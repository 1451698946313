<template>
  <div id="container">
    <section id="register">
      <div class="wrap">
        <h2 class="main_ttl"><span>新規入塾のお申込み</span></h2>
        <div v-html="coupon_lead_text"></div>
        <h3 class="sub_ttl"><span>塾生クラスについて</span></h3>
        <p class="lead_txt">
          塾⽣クラスの選択をしてください。<br>
          複眼経済塾では3つのクラスをご⽤意しております。
        </p>
        <!-- ここから 固定ページ： registration_info -->
        <div v-if="fetch_data" v-html="fetch_data.body"></div>
        <!-- ここまで 固定ページ： registration_info -->
        <div class="cts_box mt50">
          <h3 class="min_ttl">クラスと受講内容について</h3>
          <p class="bold mb10">
            本⽇のお⽀払いは入塾金のみとなります。⽉謝は入塾⽉からのお⽀払いとなります。
            価格はすべて消費税込の金額です。
          </p>
          <p class="join_date" v-if="false">
            現在のお申込みで2022年7月1日の入塾です。
          </p>
          <div class="course_box fee">
            <h4><span>入塾金</span>
              <div class="fee_price"><span>税込</span>¥10,000</div>
            </h4>
          </div>
          <div class="course_box point" v-if="false">
            <h4><span>ポイント還元</span><span class="present">キャンペーンで3000ポイントプレゼント！</span>
              <div class="fee_price">3000<span>ポイント</span></div>
            </h4>
            <p class="present sp">キャンペーンで3000ポイントプレゼント！</p>
          </div>

          <div class="matrix flex">

            <div class="matrix_col pc">
              <div class="matrix_cell class_select">
              </div>
              <div class="matrix_cell flex gaiyo-title">
                <h4>概要</h4>
              </div>
              <div class="matrix_cell flex">
                <h4>月例会</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>月１回開催される主軸講義。四季報分析、日経分析、コンテストレビュー、の３種類が全国各地で開催されます。またこの模様は中継で配信されます。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>懇親会</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>月1回全国各地で開催される月例会に合わせて懇親会を開催。毎回40-50名が参加します。複眼経済塾の最大の特徴は、投資を通じた友人ができること。孤独に投資するのではなく、仲間同士で意見交換することで、より安定した運用が可能になることでしょう。また、毎回塾長・事務局長始め、スタッフが必ず参加して、懇親会でしか話せない、あんなことこんなことを直接お話する機会を設けています。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>各ワークショップ</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>会社四季報の読み方・使い方、そして日経新聞の読み方・使い方を各々半日かけてミッチリ教えます！ワークショップは基本的に都度料金がかかりますが、複数回受講される方が多いため、本科プラスコースでは月謝に料金を含めてしまい毎回無料で受講できるようにお得な設定になっています。また、本科・予科の皆さんも割引料金設定。例えば、<a href="https://www.millioneyes.jp/workshop_shikiho" target="_blank">四季報10倍ワーク（教室版）</a>や<a href="https://www.millioneyes.jp/workshop_nikkei" target="_blank">日経マジ読みワーク</a>は通常5万円のところ本科1万5千円、予科2万3千円の設定です。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>録画講義</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>初級から上級まで、毎週様々な講義が配信されています。自慢の一流講義陣による講義は、毎週や月1回、あるいはアットランダムな配信等様々。よく塾生から「全部見る時間がない」との声を頂きますが、全部見る必要はありません。塾としてはすべてのレベルの方々をカバーできるように講義を多数そろえていますが、皆さんはご自身のレベルに合ったものだけをピックアップしてご覧いただければ、十分元は取れる内容だと自負しています。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>複眼IRミーティング</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>複眼経済塾だからこそできる！上場企業社長とマン・ツー・マン・インタビューによる<a href="https://www.millioneyes.jp/irmtg/" target="_blank">IRミーティング</a>。珠玉の銘柄を発掘しよう！</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>コンテスト</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>四季報から「渾身の1社」を選んでエントリー！予科から本科プラスまで全員無料参加可能！四季報発売月の月末にエントリーできます！3カ月間と1年間の騰落を競い、1位は10万円相当、2位5万円相当、3位3万円相当のギフト券をプレゼント！！！</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>合宿・ツアー</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>複眼合宿は年１回、複眼ツアーも年１回開催。合宿は泊まり込みの勉強会です。ツアーは、大人の修学旅行。全国各地にある普段訪れることがない日本の産業の原点を探る、真にディープで本質に迫るツアー。歴史オタクでもある渡部塾長だからこそ組める行程です。この内容は、日経CNBCで「<a href="https://www.youtube.com/watch?v=_Tmu_G2izs4&amp;list=PLq4TfFKk7jJI0cjEpQyOgfQ1aqgPx6jIq" target="_blank">複眼流投資家道中膝栗毛</a>」というレギュラー番組の土台にもなりました！</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>東洋経済会社四季報ONLINE</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>東洋経済新報社と当社のタイアップ。複眼経済塾生だけの特別なプログラムを展開中！本科プラスは、無料で四季報オンラインプレミアム（年5万8千円）が使い放題！また、本科でも「年払いコース」の方は、四季報オンラインプレミアムが<a href="https://www.millioneyes.jp/shikiho_online/" target="_blank">特別割引価格</a>で使えます（通常年5万8千円のところ5万円に！）</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>プラス限定講義</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>本科プラスコースの方だけご覧いただける特別講義を用意しています！</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>プラス限定イベント</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>本科プラスの方のみ参加いただける少人数・濃密版のイベントを用意しています。塾長や事務局長等スタッフと一緒に、大人の遠足に行く「前夜祭」など、より深く、より近く複眼経済塾を体感したい方は、ぜひ本科プラス限定イベントにお越しください！</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell class_select">
              </div>
            </div><!--//matrix_col pc-->

            <!--予科-->
            <div class="matrix_col">
              <div class="matrix_cell class_select">
                <div class="inner">
                  <h3>予科</h3>
                  <div class="price flex">
                    <div class="left">月額払い</div>
                    <div class="right">4,950<span>円</span></div>
                    <div class="left pc">&nbsp;</div>
                    <div class="right pc">&nbsp;</div>
                    <span class="month_price pc">&nbsp;</span>
                  </div>
                  <p class="caution">消費税込</p>
                  <a href="javascript:void(0);" v-on:click="rankSelect('rank_preparatory_month')" class="btn">入塾する</a>
                  <div v-if="false" class="sp tggle_btn">クラスに含まれる内容を確認する</div>
                </div>
              </div>
              <div class="sp_open_cts">
                <div class="matrix_cell flex">
                  <div class="gaiyo-text">初級の方、これから投資を始める方など、基礎を学びたい方の講義に特化したコース。基礎コースのため、複眼経済塾の主軸講義である「月例会」等はご覧いただけません。まずは初歩を学んでから本科に進みたい方にお薦め。
                  </div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>月例会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>月１回開催される主軸講義。四季報分析、日経分析、コンテストレビュー、の３種類が全国各地で開催されます。またこの模様は中継で配信されます。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="×"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>懇親会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>月1回全国各地で開催される月例会に合わせて懇親会を開催。毎回40-50名が参加します。複眼経済塾の最大の特徴は、投資を通じた友人ができること。孤独に投資するのではなく、仲間同士で意見交換することで、より安定した運用が可能になることでしょう。また、毎回塾長・事務局長始め、スタッフが必ず参加して、懇親会でしか話せない、あんなことこんなことを直接お話する機会を設けています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="×"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>各ワークショップ</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>会社四季報の読み方・使い方、そして日経新聞の読み方・使い方を各々半日かけてミッチリ教えます！ワークショップは基本的に都度料金がかかりますが、複数回受講される方が多いため、本科プラスコースでは月謝に料金を含めてしまい毎回無料で受講できるようにお得な設定になっています。また、本科・予科の皆さんも割引料金設定。例えば、<a href="https://www.millioneyes.jp/workshop_shikiho" target="_blank">四季報10倍ワーク（教室版）</a>や<a href="https://www.millioneyes.jp/workshop_nikkei" target="_blank">日経マジ読みワーク</a>は通常5万円のところ本科1万5千円、予科2万3千円の設定です。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>別途料金</span></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>録画講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>初級から上級まで、毎週様々な講義が配信されています。自慢の一流講義陣による講義は、毎週や月1回、あるいはアットランダムな配信等様々。よく塾生から「全部見る時間がない」との声を頂きますが、全部見る必要はありません。塾としてはすべてのレベルの方々をカバーできるように講義を多数そろえていますが、皆さんはご自身のレベルに合ったものだけをピックアップしてご覧いただければ、十分元は取れる内容だと自負しています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>一部のみ</span></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>複眼IRミーティング</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼経済塾だからこそできる！上場企業社長とマン・ツー・マン・インタビューによる<a href="https://www.millioneyes.jp/irmtg/" target="_blank">IRミーティング</a>。珠玉の銘柄を発掘しよう！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>コンテスト</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>四季報から「渾身の1社」を選んでエントリー！予科から本科プラスまで全員無料参加可能！四季報発売月の月末にエントリーできます！3カ月間と1年間の騰落を競い、1位は10万円相当、2位5万円相当、3位3万円相当のギフト券をプレゼント！！！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>合宿・ツアー</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼合宿は年１回、複眼ツアーも年１回開催。合宿は泊まり込みの勉強会です。ツアーは、大人の修学旅行。全国各地にある普段訪れることがない日本の産業の原点を探る、真にディープで本質に迫るツアー。歴史オタクでもある渡部塾長だからこそ組める行程です。この内容は、日経CNBCで「<a href="https://www.youtube.com/watch?v=_Tmu_G2izs4&amp;list=PLq4TfFKk7jJI0cjEpQyOgfQ1aqgPx6jIq" target="_blank">複眼流投資家道中膝栗毛</a>」というレギュラー番組の土台にもなりました！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>東洋経済会社四季報ONLINE</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>東洋経済新報社と当社のタイアップ。複眼経済塾生だけの特別なプログラムを展開中！本科プラスは、無料で四季報オンラインプレミアム（年5万8千円）が使い放題！また、本科でも「年払いコース」の方は、四季報オンラインプレミアムが<a href="https://www.millioneyes.jp/shikiho_online/" target="_blank">特別割引価格</a>で使えます（通常年5万8千円のところ5万円に！）</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラスコースの方だけご覧いただける特別講義を用意しています！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定イベント</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラスの方のみ参加いただける少人数・濃密版のイベントを用意しています。塾長や事務局長等スタッフと一緒に、大人の遠足に行く「前夜祭」など、より深く、より近く複眼経済塾を体感したい方は、ぜひ本科プラス限定イベントにお越しください！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell class_select pc">
                  <div class="inner">
                    <h3>予科</h3>
                    <div class="price flex">
                      <div class="left">月額払い</div>
                      <div class="right">4,950<span>円</span></div>
                      <div class="left">&nbsp;</div>
                      <div class="right">&nbsp;</div>
                      <span class="month_price">&nbsp;</span>
                    </div>
                    <p class="caution">消費税込</p>
                    <a href="javascript:void(0);" v-on:click="rankSelect('rank_preparatory_month')"
                       class="btn">入塾する</a>
                  </div>
                </div>
              </div>
            </div><!--//matrix_col 予科-->

            <!--本科-->
            <div class="matrix_col">
              <div class="matrix_cell class_select">
                <div class="inner">
                  <h3>本科</h3>
                  <div class="price flex">
                    <div class="left">月額払い</div>
                    <div class="right">16,500<span>円</span></div>
                    <div class="left">年額払い</div>
                    <div class="right">162,000<span>円</span></div>
                    <span class="month_price">（月額換算：13,500円）</span>
                  </div>
                  <p class="caution">消費税込</p>
                  <a href="javascript:void(0);" v-on:click="rankSelect('rank_regular_year')" class="btn">入塾する</a>
                  <div v-if="false" class="sp tggle_btn">クラスに含まれる内容を確認する</div>
                </div>
              </div>
              <div class="sp_open_cts">
                <div class="matrix_cell flex">
                  <div class="gaiyo-text">複眼の主軸コース。塾生の８割がこのコースに在籍。最重要講義である「月例会」や「複眼ポートフォリオレビュー」など、投資を実践するための講義が満載。合宿、ツアーや懇親会も本科より参加いただけます。</div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>月例会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>月１回開催される主軸講義。四季報分析、日経分析、コンテストレビュー、の３種類が全国各地で開催されます。またこの模様は中継で配信されます。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>懇親会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>月1回全国各地で開催される月例会に合わせて懇親会を開催。毎回40-50名が参加します。複眼経済塾の最大の特徴は、投資を通じた友人ができること。孤独に投資するのではなく、仲間同士で意見交換することで、より安定した運用が可能になることでしょう。また、毎回塾長・事務局長始め、スタッフが必ず参加して、懇親会でしか話せない、あんなことこんなことを直接お話する機会を設けています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>各ワークショップ</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>会社四季報の読み方・使い方、そして日経新聞の読み方・使い方を各々半日かけてミッチリ教えます！ワークショップは基本的に都度料金がかかりますが、複数回受講される方が多いため、本科プラスコースでは月謝に料金を含めてしまい毎回無料で受講できるようにお得な設定になっています。また、本科・予科の皆さんも割引料金設定。例えば、<a href="https://www.millioneyes.jp/workshop_shikiho" target="_blank">四季報10倍ワーク（教室版）</a>や<a href="https://www.millioneyes.jp/workshop_nikkei" target="_blank">日経マジ読みワーク</a>は通常5万円のところ本科1万5千円、予科2万3千円の設定です。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>別途料金</span></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>録画講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>初級から上級まで、毎週様々な講義が配信されています。自慢の一流講義陣による講義は、毎週や月1回、あるいはアットランダムな配信等様々。よく塾生から「全部見る時間がない」との声を頂きますが、全部見る必要はありません。塾としてはすべてのレベルの方々をカバーできるように講義を多数そろえていますが、皆さんはご自身のレベルに合ったものだけをピックアップしてご覧いただければ、十分元は取れる内容だと自負しています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>複眼IRミーティング</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼経済塾だからこそできる！上場企業社長とマン・ツー・マン・インタビューによる<a href="https://www.millioneyes.jp/irmtg/" target="_blank">IRミーティング</a>。珠玉の銘柄を発掘しよう！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>コンテスト</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>四季報から「渾身の1社」を選んでエントリー！予科から本科プラスまで全員無料参加可能！四季報発売月の月末にエントリーできます！3カ月間と1年間の騰落を競い、1位は10万円相当、2位5万円相当、3位3万円相当のギフト券をプレゼント！！！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>合宿・ツアー</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼合宿は年１回、複眼ツアーも年１回開催。合宿は泊まり込みの勉強会です。ツアーは、大人の修学旅行。全国各地にある普段訪れることがない日本の産業の原点を探る、真にディープで本質に迫るツアー。歴史オタクでもある渡部塾長だからこそ組める行程です。この内容は、日経CNBCで「<a href="https://www.youtube.com/watch?v=_Tmu_G2izs4&amp;list=PLq4TfFKk7jJI0cjEpQyOgfQ1aqgPx6jIq" target="_blank">複眼流投資家道中膝栗毛</a>」というレギュラー番組の土台にもなりました！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>東洋経済会社四季報ONLINE</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>東洋経済新報社と当社のタイアップ。複眼経済塾生だけの特別なプログラムを展開中！本科プラスは、無料で四季報オンラインプレミアム（年5万8千円）が使い放題！また、本科でも「年払いコース」の方は、四季報オンラインプレミアムが<a href="https://www.millioneyes.jp/shikiho_online/" target="_blank">特別割引価格</a>で使えます（通常年5万8千円のところ5万円に！）</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>追加料金</span></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラスコースの方だけご覧いただける特別講義を用意しています！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定イベント</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラスの方のみ参加いただける少人数・濃密版のイベントを用意しています。塾長や事務局長等スタッフと一緒に、大人の遠足に行く「前夜祭」など、より深く、より近く複眼経済塾を体感したい方は、ぜひ本科プラス限定イベントにお越しください！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell class_select pc">
                  <div class="inner">
                    <h3>本科</h3>
                    <div class="price flex">
                      <div class="left">月額払い</div>
                      <div class="right">16,500<span>円</span></div>
                      <div class="left">年額払い</div>
                      <div class="right">162,000<span>円</span></div>
                      <span class="month_price">（月額換算：13,500円）</span>
                    </div>
                    <p class="caution">消費税込</p>
                    <a href="javascript:void(0);" v-on:click="rankSelect('rank_regular_year')" class="btn">入塾する</a>
                  </div>
                </div>
              </div>
            </div><!--//matrix_col 本科-->

            <!--本科プラス-->
            <div class="matrix_col">
              <div class="matrix_cell class_select">
                <div class="inner">
                  <h3>本科プラス</h3>
                  <div class="price flex">
                    <div class="left">月額払い</div>
                    <div class="right">27,500<span>円</span></div>
                    <div class="left">年額払い</div>
                    <div class="right">264,000<span>円</span></div>
                    <span class="month_price">（月額換算：22,000円）</span>
                  </div>
                  <p class="caution">消費税込</p>
                  <a href="javascript:void(0);" v-on:click="rankSelect('rank_regular_plus_year')" class="btn">入塾する</a>
                  <div v-if="false" class="sp tggle_btn">クラスに含まれる内容を確認する</div>
                </div>
              </div>
              <div class="sp_open_cts">
                <div class="matrix_cell flex">
                  <div class="gaiyo-text">最上級コース。全てのワークショップが追加料金無しで受け放題。全イベント優先申込。プラス限定イベントと講義に、四季報ONLINEプレミアムが付いて、このお値段はお得！最もディープに複眼を使いこなしたいなら本科プラス。</div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>月例会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>月１回開催される主軸講義。四季報分析、日経分析、コンテストレビュー、の３種類が全国各地で開催されます。またこの模様は中継で配信されます。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>懇親会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>月1回全国各地で開催される月例会に合わせて懇親会を開催。毎回40-50名が参加します。複眼経済塾の最大の特徴は、投資を通じた友人ができること。孤独に投資するのではなく、仲間同士で意見交換することで、より安定した運用が可能になることでしょう。また、毎回塾長・事務局長始め、スタッフが必ず参加して、懇親会でしか話せない、あんなことこんなことを直接お話する機会を設けています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>各ワークショップ</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>会社四季報の読み方・使い方、そして日経新聞の読み方・使い方を各々半日かけてミッチリ教えます！ワークショップは基本的に都度料金がかかりますが、複数回受講される方が多いため、本科プラスコースでは月謝に料金を含めてしまい毎回無料で受講できるようにお得な設定になっています。また、本科・予科の皆さんも割引料金設定。例えば、<a href="https://www.millioneyes.jp/workshop_shikiho" target="_blank">四季報10倍ワーク（教室版）</a>や<a href="https://www.millioneyes.jp/workshop_nikkei" target="_blank">日経マジ読みワーク</a>は通常5万円のところ本科1万5千円、予科2万3千円の設定です。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>録画講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>初級から上級まで、毎週様々な講義が配信されています。自慢の一流講義陣による講義は、毎週や月1回、あるいはアットランダムな配信等様々。よく塾生から「全部見る時間がない」との声を頂きますが、全部見る必要はありません。塾としてはすべてのレベルの方々をカバーできるように講義を多数そろえていますが、皆さんはご自身のレベルに合ったものだけをピックアップしてご覧いただければ、十分元は取れる内容だと自負しています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>複眼IRミーティング</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼経済塾だからこそできる！上場企業社長とマン・ツー・マン・インタビューによる<a href="https://www.millioneyes.jp/irmtg/" target="_blank">IRミーティング</a>。珠玉の銘柄を発掘しよう！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>コンテスト</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>四季報から「渾身の1社」を選んでエントリー！予科から本科プラスまで全員無料参加可能！四季報発売月の月末にエントリーできます！3カ月間と1年間の騰落を競い、1位は10万円相当、2位5万円相当、3位3万円相当のギフト券をプレゼント！！！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>合宿・ツアー</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼合宿は年１回、複眼ツアーも年１回開催。合宿は泊まり込みの勉強会です。ツアーは、大人の修学旅行。全国各地にある普段訪れることがない日本の産業の原点を探る、真にディープで本質に迫るツアー。歴史オタクでもある渡部塾長だからこそ組める行程です。この内容は、日経CNBCで「<a href="https://www.youtube.com/watch?v=_Tmu_G2izs4&amp;list=PLq4TfFKk7jJI0cjEpQyOgfQ1aqgPx6jIq" target="_blank">複眼流投資家道中膝栗毛</a>」というレギュラー番組の土台にもなりました！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>東洋経済会社四季報ONLINE</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>東洋経済新報社と当社のタイアップ。複眼経済塾生だけの特別なプログラムを展開中！本科プラスは、無料で四季報オンラインプレミアム（年5万8千円）が使い放題！また、本科でも「年払いコース」の方は、四季報オンラインプレミアムが<a href="https://www.millioneyes.jp/shikiho_online/" target="_blank">特別割引価格</a>で使えます（通常年5万8千円のところ5万円に！）</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラスコースの方だけご覧いただける特別講義を用意しています！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定イベント</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラスの方のみ参加いただける少人数・濃密版のイベントを用意しています。塾長や事務局長等スタッフと一緒に、大人の遠足に行く「前夜祭」など、より深く、より近く複眼経済塾を体感したい方は、ぜひ本科プラス限定イベントにお越しください！</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell class_select pc">
                  <div class="inner">
                    <h3>本科プラス</h3>
                    <div class="price flex">
                      <div class="left">月額払い</div>
                      <div class="right">27,500<span>円</span></div>
                      <div class="left">年額払い</div>
                      <div class="right">264,000<span>円</span></div>
                      <span class="month_price">（月額換算：22,000円）</span>
                    </div>
                    <p class="caution">消費税込</p>
                    <a href="javascript:void(0);" v-on:click="rankSelect('rank_regular_plus_year')"
                       class="btn">入塾する</a>
                  </div>
                </div>
              </div>
            </div><!--//matrix_col 本科プラス-->

          </div>

          <!--//matrix-->
          <ul class="caution_num" v-if="false">
            <li>四季報10倍ワークショップは教室版とオンライン版があり、価格が異なります。詳細は<a href="https://www.millioneyes.jp/workshop_shikiho/" target="_blank">こちら</a>をご覧ください。
            </li>
            <li>新型コロナウィルス感染拡大防止の為開催されない場合があります。</li>
          </ul>
        </div>
        <div class="cts_box">
          <h3 class="min_ttl">「会社四季報ONLINE」について</h3>
          <p>
            「本科プラス」受講の⽅は【会社四季報ONLINE プレミアムプラン】が含まれています。<br>
            「本科」受講の⽅は、オプション追加で特別塾⽣割引価格で【会社四季報ONLINE
            プレミアムプラン・ベーシックプラン】をご利⽤いただけます。【会社四季報ONLINE】のプランにつきましては
            <a href="https://shikiho.toyokeizai.net/" target="_blank">【会社四季報ONLINE】のページをご覧ください。</a>
          </p>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">月謝のお支払い方法についてのご注意</h3>
          <div class="payment_notice flex">
            <div class="payment_notice_box">
              <ul class="dotted">
                <li class="ttl"><h4>月額払いをご希望の場合</h4></li>
                <li>毎月１日に月謝の引き落とし決済を、ご登録クレジットカードにて行います。</li>
                <li>退会は、各月にいつでも行う事ができます。退会申請日の月末までご利用いただけます。</li>
                <li>
                  東洋経済新報社の会社四季報ONLINE特別割引は、「本科（年払）」の方のみ選択が可能です。「本科（月払）」「予科（月払）」を選択の方は、会社四季報ONLINE特別割引をオプション契約することはできません。ご希望の方は、「本科（年払）」にクラスを変更してお申込みください。
                </li>
                <li>「本科プラス（月払）」には、会社四季報ONLINEプレミアムコースが自動的に付与されます。</li>
              </ul>
            </div>

            <div class="payment_notice_box">
              <ul class="dotted">
                <li class="ttl"><h4>年額払いをご希望の場合</h4></li>
                <li>年額払いは、入塾日の最初の月の１日に１年分の月謝をまとめてご登録クレジットカードより引き落としいたします。</li>
                <li>
                  年払割引が適用されておりますので、年途中での退会やクラス変更ができません。退会は年払の満了日（7月入塾の場合、翌年6月末日）での退会のみとなります。十分ご検討の上、年額払いにお申込みください。
                </li>
                <li>
                  東洋経済新報社の会社四季報ONLINE特別割引は、「本科（年払）」の方のみ選択が可能です。年途中でのオプション申込が可能です。ただし、オプションを外したい場合は、年途中での解約ができません。予めご了承ください。
                </li>
                <li>「本科プラス（年払）」には、会社四季報ONLINEプレミアムコースが自動的に付与されます。</li>
              </ul>
            </div>
          </div>
        </div>
      </div><!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {},
  data() {
    if (!this.$store.state.user) {
      this.$store.state.user = {
        rank: 'rank_regular_plus_year'
      }
    } else {
      this.$store.state.user.agreement = false
    }
    return {
      new_user: this.$store.state.user,
      campaign_code: this.$route.params.campaign_code,
      coupon_lead_text: null,
      fetch_data: null,
    }
  },
  mounted() {
    if (this.campaign_code) {
      this.checkCouponCode()
    } else {
      if (!this.isRegistrationEnabled() && (!this.isRegistrationDebugEnabled() ||
          (this.$route.query.debug !== 'millioneyes' && this.$route.query.special_registration !== 'millioneyes'))) {
        // LPへ
        this.$router.push({name: 'lp'})
        return
      }
    }
    this.rankSelect('rank_regular_month')

    /*
    // 新規入塾クラス選択テキストを固定ページから取得
    this.axios
        .get(`${this.env.api_base_url}contents.json`, {
          params: {
            uri: 'registration_info',
            draft: this.$route.query.draft
          },
        })
        .then(response => {
          this.fetch_data = response.data
        })

    // 早割ポイントの取得
    /*
    this.startAjax()
    this.axios
        .get(`${this.env.api_base_url}term_point.json`)
        .then(response => {
          this.fetch_data = response.data
        })
        .catch((response) => {
          this.errorAjax(response)
        })
        .finally(() => {
          this.finishAjax()
        })
     */

    /*
    if (typeof _million_onload !== 'undefined') {
      // window._million_onload(this.fetch_data.uri);
    }
    // for jquery.
    window.$(".matrix .info span").on("click", function () {
      if (window.$(this).hasClass("open")) {
        window.$(".matrix_cell .info span").removeClass("open");
        window.$(".matrix_cell .info .info_box").hide();
        window.$(this).closest(".matrix_cell").find(".info_box").fadeOut(300);
        window.$(this).removeClass("open");
      } else {
        window.$(".matrix_cell .info span").removeClass("open");
        window.$(".matrix_cell .info .info_box").hide();
        window.$(this).closest(".matrix_cell").find(".info_box").fadeIn(300);
        window.$(this).addClass("open");
      }
    });
    window.$(".matrix .info_box .colse_btn,.matrix .info_box a").on("click", function () {
      window.$(this).closest(".matrix_cell").find(".info span").removeClass("open");
      window.$(this).closest(".matrix_cell").find(".info_box").fadeOut(300);
    })
    window.$(".matrix .tggle_btn").on("click", function () {
      window.$(this).closest(".matrix_col");
      window.$(this).closest(".matrix_col").find(".sp_open_cts").slideToggle();
      window.$(this).closest(".matrix_col").toggleClass("open");
    });

    */
  },

  methods: {
    checkCouponCode() {
      this.startAjax()
      this.axios
          .post(`${this.env.api_base_url}users/campaign_code.json`, {
            campaign_code: this.campaign_code,
          })
          .then((response) => {
            this.coupon_lead_text = response.data.lead_text
          })
          .catch((error) => {
            if (error.response.data.code && error.response.data.code === 400) {
              this.$router.push({path: '/customer/account/partner/close'})
            } else {
              this.$router.push({name: '404'})
            }
          })
          .finally(() => {
            this.finishAjax()
          })
    },
    rankSelect(rank) {
      rank = 'rank_regular_month'
      this.new_user.rank = rank
      this.$router.push(
          this.campaign_code ? {
            name: 'UsersNewRegistrationPartner',
            params: {campaign_code: this.campaign_code},
            query: this.$route.query
          } : {
            name: 'UsersNewRegistration',
            query: this.$route.query
          }
      )
    }
  }
}
</script>
